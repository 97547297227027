// zh.js
export default {
	// 可以以页面为单位来写，比如首页的内容，写在index字段，个人中心写在center，公共部分写在common部分
	lang: {
		title: "uView UI",
		intro: "多平台快速开发的UI框架",
	},
	loain: {
		_Inscreverse: "Registro",
		_AcessoDeMembro: "Login",
	},
	_home: {
		_Popularjogos: "Popular jogos",
		_Aexibir: " A exibir ",
		_jogosentre: "jogos entre",
		_Carregarmais: "Carregar mais",
		_Eventos: "Eventos",
		_Missão: "Missão",
		_Rebate: "Rebate",
		_Pendente: "Pendente",
		_VIP: "VIP",
		_Convidar: "Convidar",
		_FundodePrevidência: "Fundo de Previdência",
		_Slots: "Slots",
		_Pescaria: "Pescaria",
		_Demo: "Demo",
		_Suporteonline: "Suporte online",
		_CentraldeAjuda: "Central de Ajuda",
		_BônusdeSugestão: "Bônus de Sugestão",
		_Cassino: "Cassino",
		_Jogos: "Jogos",
		_Suporte: "Suporte",
		_Contatenos: "Contate-nos",
		_tis:"O jogo está em manutenção",
		
	},
	_VIP: {
		_Lembrete: "Lembrete",
		_LembreteConter: "Para avançar para o próximo nível, é necessário fazer apostas adicionais além do total acumulado de apostas válidas. Por exemplo: se o requisito de apostas válidas para avançar para o VIP1 é de 1000, e o requisito para avançar para o VIP2 é de 2000, então o membro precisa acumular um total de 1000 + 2000 = 3000 apostas válidas para avançar para o VIP2, e assim por diante.",
		_NívelAtual: "Nível Atual",
		_Restantes: "Restantes",
		_Apostemais: "Aposte mais",
		_ResgatarTudo: "Resgatar Tudo",
		_Histórico: "Histórico",
		_ListadeníveisVIP: "Lista de níveis VIP",
		_InstruçõessobreregrasVIP: "Instruções sobre regras VIP",
		_Nível: "Nível",
		_Apostaparapromoção: "Aposta para promoção",
		_BônusDeAumentoDe: "Bônus De Aumento De ",
		_Bônus: "Bônus",
		_Ovalortotaldaretiradadiária: "O valor total da retirada diária",
		_Olimitesuperiordaretiradadiária: "O limite superior da retirada diária",
		_BôTaxasdeisençãodiáriacaneta: "BôTaxas de isenção diária caneta",
		_Totaldedepósitos: "Total de depósitos",
		_FundodePrevidência: "Fundo de Previdência",
		_Depósito: "Depósito",
		_Retira: "Retira",
		_Nãosertapado: "Não ser tapado",
		_BônusMáximoNãosertapado: "Bônus Máximo",
		_LimiteMáximoNãosertapado: "Limite Máximo",
		_acumulaçãototal0: "acumulação total",
		_Horário: "Horário",
		_Depósito: "Depósito",
		_Taxadebônus: "Taxa de bônus",
		_Taxadebônus: "Taxa de bônus",
		_Númerodeapostas: "Número de apostas",
		_Novosrequerimentosparaaposta: "Novos requerimentos para aposta",
		_Vocêprecisaapostar: "Você precisa apostar",
	},
	yuebao: {
		_Depositado: "Depositado",
		_Ciclodeassentamento1horas: "Ciclo de assentamento\n1 horas",
		_Coleçãocumulativa: "Coleção cumulativa",
		_MáximodejurosIlimitado: "Máximo de juros\nIlimitado",
		_TaxadeJurosAnual100: "Taxa de Juros Anual 100.00%",
		_Depósito: "Depósito",
		_Transferir: "Transferir",
		_Receber: "Receber",
		_Horas: "Horas",
		_Tipos: "Tipos",
		_Quantia: "Quantia",
		_RendaAcumulada0: "Renda Acumulada",
		actiVCo: [{
			text: "Hoje",
			value: 0
		}, {
			text: "Ontem",
			value: 1
		}, {
			text: "Últimos 7 Dias",
			value: 2
		}, {
			text: "Últimos 15 Dias",
			value: 3
		}, {
			text: "Últimos 30 Dias",
			value: 4
		}],
		actiVCo1: [{
			text: "Tudo",
			value: 0
		}, {
			text: "Depósito",
			value: 1
		}, {
			text: "Transferir",
			value: 2
		}, {
			text: "Receber Saldo",
			value: 3
		}]
	},
	description: "Sem Registros",

	toolbar: [{
			title: "Inicio"
		},
		{
			title: "Promoção"
		},
		{
			title: "Agente"
		},
		{
			title: "Suporte"
		},
		{
			title: "Perfil"
		},
	],
	toolbarConter:{
		_Inicio:"Inicio",
		_Promoção:"Promoção",
		_Agente:"Agente",
		_Suporte:"Suporte",
		_Perfil:"Perfil",
		_Depósito:'Depósito',
		_Saque:"Saque",
	},
	popup: {
		nav: [{
				title: "Popular"
			},
			{
				title: "Slots"
			},
			{
				title: "Pescaria"
			},
			{
				title: "Demo"
			},
			{
				title: "Recente"
			},
			{
				title: "Favoritos"
			},
		],
		_Slots:"Slots",
		_Demo:'Demo',
		_Recente:"Recente",
		_Popular:"Popular",
		_Pescaria:"Pescaria",
		_Favoritos:"Favoritos",
		_Apostas: "Apostas",
		_Agente: "Agente",
		_Promoção: "Promoção",
		_Eventos: "Eventos",
		_Histórico: "Histórico",
		_FundodePrevidência: "Fundo de  Previdência",
		_Pendente: "Pendente",
		_Juros: "Juros",
		_VIP: "VIP",
		_Português: "Português",
		_BaixarApp: "Baixar App",
		_Suporte: "Suporte",
		_FAQ: "FAQ",
		_Sobre: "Sobre",
		_Aexibir: "A exibir",
		_jogosEntre: "jogos entre",
		_PopularIogos: "Popular jogos",
		_Carregarmais: "Carregar mais",
		_BônusDeAumentoDeNível: "Bônus De Aumento De Nível",
		_BônusSemanal: "Bônus Semanal",
		_BônusMensal: "Bônus Mensal",
		_PrivilégioVIP: "Privilégio VIP",
		_Detalhesdoregistro: "Detalhes do registro",
		_Regrasdejuros: "Regras de juros",
		_Totalderegistros: "Total de registros",
		_RequisitosDeapostas: "Requisitos de apostas",
		_Regras: "Regras",
	},
	share: {
		_LinkdeConvite: "Link de Convite",
		_MeusDados: "Meus Dados",
		_TodososDados: "Todos os Dados",
		_Desempenho: "Desempenho",
		_Comissão: "Comissão",
		_DadosdoSubordinado: "Dados do Subordinado",
		_ApostasdosSubordinados: "Apostas dos Subordinados",
		_TaxadeComissão: "Taxa de Comissão",
		_CadastrarSubordinados: "Cadastrar Subordinados",
	},
	notice: {
		_Suporte: "Suporte",
		_Notícia: "Notícia",
		_Notificação: "Notificação",
		_PainelRolante: "Painel Rolante",
		_BônusdeSugestão: "Bônus de Sugestão",
		_TelegramSuporte: "Telegram Suporte",
		_FacebookSuporte: "Facebook Suporte",
		_FAQ: "FAQ",
		_PerguntasFreqüentes: "Perguntas Freqüentes",
		_Apoioonline: "Apoio online",
		_Contactaragora: "Contactar agora",
		_Conversecomoserv: "Converse com o serviço profissional de apoio ao cliente online para resolver os seus problemas",
		_SemMensagem: "Sem Mensagem",
	},
	withdraw: {
		_Solicitarsaque: "Solicitar saque",
		_TarefasdeApostas: "Tarefas de Apostas",
		_Contapararecebimento: "Conta para recebimento",
		_RegistrodeSaques: "Registro de Saques",
		_Paraasegurança: "Para a segurança da sua conta, introduza a palavra-passe de levantamento",
		_EsqueceuaSenha: "Esqueceu a Senha?",
		_Próximo: "Próximo",
		_InserirSenha: "Inserir Senha",
		_VerificarSenhadeSaque: "Verificar Senha de Saque",
		_DetalhesdeRetiradas: "Detalhes de Retiradas",
		_Tipodetransação: "Tipo de transação",
		_MétododeRetirada: "Método de Retirada",
		_HoradeCriação: "Hora de Criação",
		_Horadeexecução: "Hora de execução",
		_NúmerodoPedido: "Número do Pedido",
		_Vocêtambém: "Você também precisa apostar efetivamente",
		_VocêtambémRight:"para sacar dinheiro!",
		_Vezes: "Vezes",
		_Apostaválidanecessária: "Aposta válida necessária",
		_Adicionarponto: "Adicionar ponto",
		_Completado: "Completado",
		_fonte: "fonte",
		_Saldo: "Saldo",
		_Vocêprecisaapostar: "Você precisa apostar",
		_sacardinheiro: "sacar dinheiro",
		_Bonus: "Bonus",
		_Estataxade: "Esta taxa de manuseio≈R$ 0,00(chegada estimadaR$ 1.111,00)",
		_Tudo: "Tudo",
		_Vejaasregrasparadetalhes: "Veja as regras para detalhes",
		_Ganharjuros: "Ganhar juros",
		_Confirmarretirada: "Confirmar retirada",
		_TotaldeSaques: "Total de Saques",
		_EmitindoparaPIX: "Emitindo paraPIX",
		_AdicionarPIX: "Adicionar PIX",
		_Confirmar: "Confirmar",
		_Verifique: "Verifique cuidadosamente o nome e o número do cartão, caso contrário, não poderá ser creditado",
		_Contapararecebimento: "Conta para recebimento",
		_Detalhesdatarefadeapostas: "Detalhes da tarefa de apostas",
		_Tipodefonte: "Tipo de fonte",
		_HoradeCriação: "Hora de Criação",
		_ObterAdicionarpontos: "Obter/Adicionar pontos",
		_Exigirmultiplicadordeaposta: "Exigir multiplicador de aposta",
		_Apostaválidanecessária: "Aposta válida necessária",
		_Apostajáválida: "Aposta já válida",
		_Vocêprecisaapostar: "Você precisa apostar",
		_Statusdatarefa: "Status da tarefa",
		_TempodeConclusão: "Tempo de Conclusão",
		_Depósito: "Depósito",
		_Adicionar: "Adicionar",
	},
	subgame: {
		_Slots: "Slots",

	},
	promote: {
		_MeuLink: "Meu Link",
		_MododeAgente: "Modo de Agente",
		_RededoAgente: "Rede do Agente",
		_Nœud: "Différence de niveau infini (Nœud de jour)",
		_Coletável: "Coletável",
		_Subordinadosdiretos: "Subordinados diretos",
		_CódigodeConvite: "Código de Convite",
		_Receber: "Receber",
		_Histórico: "Histórico",
		_CliqueparaSalvar: "Clique para Salvar",
		_Adicionarmembrosdiretos: "Adicionar membros diretos",
		_PrimeirosDepósitos: "Primeiros Depósitos",
		_Depósitos: "Depósitos",
		_Depósito: "Depósito",
		_Desempenho: "Desempenho",
		_Comissão: "Comissão",
		_VisãoGeraldosDados: "Visão Geral dos Dados",
		_MeuTime: "Meu Time",
		_Desempenho: "Desempenho",
		_Comissão: "Comissão",
		_Membros: "Membros",
		_MembrosDiretos: "Membros Diretos",
		_OutrosMembros: "Outros Membros",
		_RendimentoTotal: "Rendimento Total",
		_DDireto: "D. Direto",
		_DdosOutros: "D. dos Outros",
		_ComissõesTotais: "Comissões Totais",
		_MemberID: "Member ID",
		_ApostasVálidas: "Apostas Válidas",
		_RecargaDireta: "Recarga Direta",
		_Primeirarecargadireta: "Primeira recarga direta",
		_OutroDepósito: "Outro Depósito",
		_Outrasrecargasiniciais: "Outras recargas iniciais",
		_DepósitoTotal: "Depósito Total",
		_Totaldejogadoresfazendoprimeirosdepósitos: "Total de jogadores fazendo primeiros depósitos",
		_IDdeMembro: "ID de Membro",
		_ComissãoDireta: "Comissão Direta",
		_OutraComissão: "Outra Comissão",
		_SubordinadosDele: "Subordinados Dele",
		_Depósito: "Depósito",
		_DatadeCadastro: "Data de Cadastro",
		_ApostasVálidas: "Apostas Válidas",
		_Datadelogin: "Data de login",
		_Atual: "Atual",
		_Estado: "Estado",
		_Númerodeinscritos: "Número de inscritos",
		_Apostasválidas: "Apostas válidas",
		_Apostaválidadireta: "Aposta válida direta",
		_VDdiretas: "V/D diretas",
		_Depósitos: "Depósitos",
		_PrimeirosDepósitos: "Primeiros Depósitos",
		_Depósito: "Depósito",
		_ApostasVálidas: "Apostas Válidas",
		_Ontem: "Ontem",
		_Hoje: "Hoje",
		_EstáSemana: "Está Semana",
		_ÚltimaSemana: "Última Semana",
		_EsteMês: "Este Mês",
		_MêsPassado: "Mês Passado",
		_DataLiquidação: "Data Liquidação",
		_customerservice: "customer service",
	},
	mine: {
		_RecuperaroSaldo: "Recuperar o Saldo",
		_Conta: "Conta",
		_Apostas: "Apostas",
		_Relatório: "Relatório",
		_GestãoRetiradas: "Gestão Retiradas",
		_Convidar: "Convidar",
		_Dados: "Dados",
		_Segurança: "Segurança",
		_Música: "Música",
		_Idioma: "Idioma",
		_FAQ: "FAQ",
		_BônusdeSugestão: "Bônus de Sugestão",
		_Façaloginnodispositivo: "Faça login no dispositivo",
		_Sobre: "Sobre",
		_Sair: "Sair",
		_Suporte: "Suporte",
		_Mensagens: "Mensagens",
		_Dados: "Dados",
		_Confirmarsaída: "Confirmar saída",
		_Cancelar: "Cancelar",
		_Desejaterminarsessãodaconta: "Deseja terminar sessão da conta?",
		_Conta: "Conta",
		_Lembrete: "Lembrete",
		_Saque: "Saque",
		_Depósito: "Depósito",
		_Juros: "Juros",
		_FundodePrevidência: "Fundo de Previdência",
		_Apostaparapromoção: "Aposta para promoção",
		_Restantes: "Restantes",
		_Valorrestanteparaaposta: "Valor restante para aposta",
		_Apostaparapromoção: "Aposta para promoção",
	},
	Login: {
		_Login: "Login",
		_Registro: "Registro",
		_Depósito: "Depósito",
		_Saque: "Saque",
		_Juros: "Juros",
		_RegistrodeDepósito: "Registro de Depósito",
		_Depósitoonline: "Depósito on-line",
		_Expandir: "Expandir",
		_Fechar: "Fechar",
		_MínimoRechare: "Mínimo ",
		_MáximoRechare: ", Máximo ",
		_Lembrete: "Lembrete：Clique em 'Comprar Saldo', selecione a quantia que você precisa, recarregue para aumentar seus pontos! !",
		_RecarregueAgora: "Recarregue Agora",
		_RegistrodeDepósito: "Registro de Depósito",
		_Abraseuaplicativo: "Abra seu aplicativo de pagamento e digitalize ou copie e cole o código QR abaixo paraconcluir sua compra;",
		_Estecódigo: "Este código QR só pode ser pago uma vez. Se precisar pagar novamente, volte erecarregue;",
		_Apósopagamento: "Após o pagamento ser bem-sucedido, você pode retornar aolobby do jogo e aguardar a adição de pontos!",
		_DepósitoTotal: "Depósito Total",
		_Pagamento: "Pagamento",
		_Pendente: "Pendente",
		_Jápago: "Recarga Bem-sucedida",
		_PedidoExpirado: "Pedido Expirado",
		_Tempoefetivo: "Tempo efetivo",
		_CopiarcódigoQR: "Copiar código QR",
		_EndereçodocódigoQR: "Endereço do código QR",
		_Statusdopedido: "Status do pedido",
		_RegistrosdeAuditoria: "Registros de Auditoria",
		_HoradeCriação: "Hora de Criação",
		_NúmerodoPedido: "Número do Pedido",
		_Númerodopedidodocomerciante: "Número do pedido do comerciante",
		_Lembre: "Lembrar da senha da conta",
		_Suporte: "Suporte Número do Celular/Conta Login",
		_Suporteaocliente: "Suporte ao cliente",
		_JogarGrátis: "Jogar Demo",
		_EsqueceuaSenha: "Esqueceu a Senha",
		_dengLuPa: "Por favor, insira Número do Celular/Conta",
		_Senha: "Senha",
		_SuporteContaRegistro: "Suporta apenas ContaRegistro",
		_Registrodocódigodeverificação: "Registro do código de verificação",
		_Cadastrodesenha: "Cadastro de senha",
		_accAcrt: "A moeda determina os jogos de terceiros e os métodos de pagamento e não pode ser modificada.",
		_Tenhomaisde: "Tenho +18 anos, li e concordo com os Termos de Uso",
		_AcordodeUsuário: "Acordo de Usuário",
		_Confirme: "Confirme a senha novamente, o mesmo que a senha!",
		_DigiteoNúmerodoCelular: "Digite o Número do Celular",
		_VerificaçãodeID: "Verificação de ID",
		_RedefiniçãodeSenha: "Redefinição de Senha",
		_Terminar: "Terminar",
		_ErroNo: "Erro no formato da conta,4-16 caracteres, suporta inglês/números",
		_Seguintes: "Seguintes",

	},
	RecuperaroSaldo: {
		_RecuperaroSaldo: "Recuperar o Saldo",
		_Saldoatual: "Saldo atual",
		_Umcliquepararecuperar: "Um clique para recuperar",
		_yaTeng: "Você só pode recuperar o múltiplo inteiro do equilíbrio (ou seja, semponto decimal)",
		_Tudos: "Tudos",
		_Pesquisadeplataforma: "Pesquisa de plataforma",
	},
	report: {
		_Conta: "Conta",
		_Apostas: "Apostas",
		_Relatório: "Relatório",
		_Valorcumulativodaaposta: "Valor cumulativo da aposta",
		_TotaldeApostasVálidas: "Total de Apostas Válidas",
		_TotaldeV: "Total de V/D",
		_DepósitoTotal: "Depósito Total",
		_TotaldeSaques: "Total de Saques",
		_AcumuladoTotal: "Acumulado Total",
		_Saldo: "Saldo",
		_ApostasVálidas: "Apostas Válidas",
		_GanhosePerdas: "Ganhos e Perdas",
		_NúmerodaNota: "Número da Nota",
	},
	security: {
		_SenhadeSaque: "Senha de Saque",
		_Paraase: "Para a segurança dos seus fundos, você precisa primeiro definir uma senha de saque!",
		_Definasuasenhadesaque: "Defina sua senha de saque",
		_NovaSenhadeSaque: "Nova Senha de Saque",
		_Nãomesmosounúmeroscontínuos: "Não pode ser 6 mesmos ou números contínuos",
		_Atenção: "Atenção: A senha de saque protege seus fundos e é extremamente importante. Mantenha-a em segredo para evitar qualquer perda financeira.",
		_Confirmar: "Confirmar",
		_Senhaincorreta: "Senha incorreta, digite novamente!",
		_Confirmenova: "Confirme a nova senha de saque",
	},
	common: {
		// ......
	},
	index: {
		// ......
	}
}
