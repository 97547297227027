<template>
	<div style="height: .9rem;">
		<div class="QP3BhRt03qv1Gwd20Gjx">
			<img :src="config.home_logo" v-lazy="config.home_logo" loading="loaded"
				class="krx_BqY0Ot5YxLi63BNa">
		
			<div class="MRkquALToHPyWfME9x1A">
				<div class="sm_KogNcllgcGMkRVpUI bWgULPbl_t2ab5S874Zm" @click="$router.push({name:'search'})">
					<i 
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.47rem;background-position: 9.1rem 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div>Pesquisar</div>
				</div>
				<!-- 5.12rem 0.5rem -->
				<div class="sm_KogNcllgcGMkRVpUI fdMVlrTiEq1Ani4KtykV" 
				@click="$store.commit('overlayShow')"
				>
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.47rem;background-position:5.12rem 0.5rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div>Música</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Icon',
		props: {

		},
		data() {
			return {
				img_hb_frame: require('./../../assets/icons/images/img_hb_frame.webp'),
				show: false,
				modelCon: false,
			}
		},
		computed: {
			...mapState(['userInfo', 'config', 'vuex_token', 'vuex_token_head', 'moneyInfo'])
		},
		created() {
			// this.qingCHuApp();
		},
		methods: {
			// <!--qingCHuApp -->
			AppDownload() {

			},
			qingCHuApp() {
				this.$vuex('xiazApp', false)
				this.$store.commit('publicFun', ['xiazApp', false])
			},
			show_Popup() {
				this.$store.commit('showPopup')
				// this.$store.commit('publicFun',['showPopup',true]) 
			}
		}
	}
</script>

<style scoped>
	.QP3BhRt03qv1Gwd20Gjx {
		height: .9rem;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 101;
		position: fixed;
		left: 0s;
		width: 100%;
		box-sizing: border-box;
		z-index: 999;
	}

	.QP3BhRt03qv1Gwd20Gjx {
		background-color: var(--theme-top-nav-bg);
		border-bottom: .01rem solid var(--theme-color-line);
		line-height: normal;
		padding: 0 .2rem 0 .1rem;
	}

	.krx_BqY0Ot5YxLi63BNa {
		display: -ms-flexbox;
		display: flex;
		height: .76rem;
		max-width: 4.15rem;
		
		min-width: 2.3rem
	}
	.sm_KogNcllgcGMkRVpUI {
		font-size: .18rem;
		text-align: center;
	}
	.MRkquALToHPyWfME9x1A{
		display: flex;
		align-items: center;
		color: var(--theme-text-color);
	}
	.sm_KogNcllgcGMkRVpUI div {
	    height: .32rem;
	    line-height: .32rem;
	    overflow: hidden;
	    text-align: center;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 100%
	}
	.fdMVlrTiEq1Ani4KtykV {
	    padding-left: .4rem;
	}
	
</style>