<template>
	<van-popup class="advertisement_-conter" v-model="isShow" z-index="2100">
		<div v-if="config.pop_page_type=='bottom'">
			<homeMete  :popList="popList"  ></homeMete>
		</div>
		<home3Dian5 @vanCheckbox="vanCheckbox" v-else :popList="popList"></home3Dian5>
		
		
		
		<div class="" style="display: flex;align-items: center;justify-content: center;margin-top: .4rem;">
			<img 
			 style="width: 0.5984rem;height: 0.5984rem;cursor: pointer;"
			 @click="$emit('CancelDisplay',typeInit2)"
			 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII=" >
		</div>
	</van-popup>
</template>

<script>
	 import { mapState } from 'vuex';
	import homeMete from "./homeMete.vue"
	import home3Dian5 from "./home3Dian5.vue"
	export default {
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config'])
		},
		props:{
			popList:{
				type:[Object,Array],
				required:[],
			},
			isShow:{
				type:Boolean,
				required:false,
			},
		},
		components: {
			homeMete,
			home3Dian5
		},
		data() {
			return {
				codeValue: '',
				typeInit2:false,
				inputFocus: false,
			};
		},
		methods: {
			vanCheckbox(e){
				this.typeInit2=e;
			},
			openUrl(e){
				if(e.jump_url && e.jump_url!='#'){
					if(/^http/.test(e.jump_url)){
						window.open(e.jump_url, '_blank');
						return false;
					}
					this.$router.push({name:e.jump_url})
				}
				// if(e.jump_url && e.jump_url!='#'){
				// 	this.$router.push({name:e.jump_url})
				// }
			}
		},
	}
</script>
<style>
	.login-body{
		font-weight: 400;
	}
	.van-popup.advertisement_-conter{
		background-color: transparent; 
	}
	
</style>
<style scoped>
	


	.advertisement_list{
		    width: 6.9rem;
			background-color: var(--theme-main-bg-color);
			border-radius: .2rem;
	}
	.advertisement_list .title{
		    color: var(--theme-text-color-darken);
			text-align: center;
			line-height: 1rem;
			font-size: .4rem;
	}
	._common-tabs-content{
		max-height: 5.4rem;
		width: 100%;
	}
	.consliTst{
		display: flex;
		align-items: center;
		border-radius: .1rem;
		height: .8rem !important;
		background-color: rgba(var(--theme-primay-color-hex), .05);
		max-width: 2.05rem;
		padding: .15rem;
		text-align: revert !important;
	}
	.consliTst svg{
		width: .38rem;
		height: .38rem;
		color: var(--theme-primary-color);
		    margin-right: .08rem;
	}
	.consliTst span{
		    font-size: .22rem;
			display: inline-block;
		    line-height: .28rem;
			flex: 1;
			    color: var(--theme-primary-color);
		    overflow: hidden;
		    text-overflow: ellipsis;
		    /* white-space: nowrap; */
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			display: -webkit-box;
				white-space: break-spaces !important;
	}
</style>