<template>
    <div>
        <van-overlay :show="$store.state.overlayShow" z-index="20000000004">
        <div class="wrapper" v-if="$store.state.overlayShow">
            <div>
				<div class="document">
				    <div class="ant-modal-header"><div id="rcDialogTitle0" class="ant-modal-title">Música</div></div>
					<div style="padding:0 24px 24px;">
						<div class="theme-box-shadow-h5">
							<div class="div-title" style="margin-bottom: .1rem;">{{CurrentPlayback.name}}</div>
							<div class="van-progress-s">
								<div style="margin-right: .4rem;">
									{{PlayTime[1]||'00'}}.{{PlayTime[2]||'00'}}
								</div>
								<div class="" style="flex: 1;display: flex;align-items: center;">
									<slisd :min="0" :max="100" @stop="jindu" :value="progress" :isDrag="true" bgColor="var(--theme-primary-color)"></slisd>
								</div>
								<div style="margin-left: .4rem;">
									{{PlayOften[1]||'00'}}:{{PlayOften[2]||'00'}}
								</div>
							</div>
							<div class="qXPKZj_vkYUaOHAZ0uBG">
								<div class="MS7oAAtXxOHs5Zg3KrYg" @click="uoenLisy"  style="padding-top: 0.06rem;">
								    <svg-icon  className="play"  :icon-class="Playing=='single'?'comm_icon_dqxh--svgSprite:all':Playing=='random'?'comm_icon_sj--svgSprite:all':'comm_icon_xhbf--svgSprite:all'"  />
									<p style="margin-top: 0.pt4rem;">Ciclo</p>
								</div>
								<div class="play_let_right" :style="{
									'background-image':`url(${_coloImg})`
								}" @click="PreviousSong">
									<svg-icon  className="play"  icon-class="comm_nav_sys--svgSprite:all"  />
								</div>
						
								<div class="play-conter" :style="{
									'background-image':`url(${_coloImg})`
								}"  @click="play">
								    <svg-icon   @click="play"  className="play" :icon-class="isPlay?'comm_nav_zt--svgSprite:all':'comm_nav_bf--svgSprite:all'"  />
								</div>
								<div  class="play_let_right" 
								:style="{
									'background-image':`url(${_coloImg})`
								}" 
								@click="NextSong">
									<svg-icon  className="play"  icon-class="comm_nav_xys--svgSprite:all"  />
								</div>
								<div class="MS7oAAtXxOHs5Zg3KrYg"  style="padding-top: 0.06rem;">
									<div class="YhLK63_jy5u0UKvEkMWQ">{{myMusicList.length||0}}</div>
									<p style="margin-top: 0.pt4rem;" class="ux46lmCXHDJO4tB3G1hK">Baixado</p>
								</div>
							</div>
							
							
						</div>
						
						<div class="qXY51eFlmkm2KItxoh96">
							<van-tabs v-model="activeName"
							class="h"
							animated
							line-height='1px'
							sticky
							offset-top=".9rem"
							background="var(--theme-main-bg-color)" 
							:ellipsis="false"
							title-active-color="var(--theme-primary-color)"
							title-inactive-color="var(--theme-text-color-darken)"
							color="var(--theme-primary-color)">
								<van-tab  title-style="font-size: 0.22rem;" v-for="(item,index) in tabList"  :title="$t(item.name)" :name='index' :key="index" >
									<p style="height: .03rem;"></p>
									<div class="xE8IyFndAfbeS8tzcBHP">
										<component :is="item.content" @onDelete="deleteFun" @onincrease="increaseFun" @xiaAzibaofan="xiaAzibaofan" :myMusic="myMusic" :myMusicList="myMusicList" :PlayList="songSheetList"></component>
									</div>
								</van-tab>
							</van-tabs>
						</div>
						
					</div>
					
					
				</div>
				<div class="" style="display: flex;align-items: center;justify-content: center;margin-top: .4rem;">
					<img 
					 style="width: 0.5984rem;height: 0.5984rem;cursor: pointer;"
					 @click="$store.commit('overlayShow')"
					 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII=" >
				</div>
			</div>
		</div>
    </van-overlay>
    </div>
</template>
<script>
	import slisd from "./slisd.vue"
	import PlayList from "./PlayList/PlayList.vue"
	import complete from "./PlayList/complete.vue"
	import {songSheet} from "@/apis/modules/songSheet.js"
	import { mapState } from 'vuex';
	// songSheet
	// audic.vue
export default {
  components:{
	  slisd,
  },
  props: {
      showPopup:{
          type: Boolean,
          required: false,
      },
  },
  computed:{
  	...mapState(['myMusic','myMusicList','songSheetList','CurrentPlayback'])
  },
  data() {
    return {
		activeName:0,
      Playing:'number',//single 单曲 random  Sequential number 顺数
      overlayShow:true,
	  tabList:[
		  {name:'Sistema de Música',content:PlayList},
		  {name:'Minhas Músicas',content:complete}
	  ],
	  audioElement:[],
	  PlayTime:[],
	  PlayOften:[],
	  progress:0,
	  isPlay:false,
    };
  },
  created() {
	  
	  // this.songSheet()
	  _player.audioElement.addEventListener('timeupdate', (e)=>{
		  let _time=setTimeout(()=>{
			  clearTimeout(_time);
			  var percentage = (_player.audioElement.currentTime / _player.audioElement.duration) * 100;
			  var currentTime=_player.audioElement.currentTime;
			  var hours = Math.floor(currentTime / 3600);
			  var minutes = Math.floor((currentTime % 3600) / 60);
			  var seconds = Math.floor(currentTime % 60);
			  seconds=seconds<10?'0'+seconds:seconds;
			  minutes=minutes<10?'0'+minutes:minutes;
			  hours=hours<10?'0'+hours:hours;
			  this.PlayTime=[hours,minutes,seconds];
			  this.progress=percentage;
		  })
	  });
	  // timeStamp
	  // var percentage = (this.audioElement.currentTime / this.audioElement.duration) * 100;
 //  	console.log(this.audioElement.duration)
	// console.log(this.audioElement.currentTime)
  },
  methods: {
	  uoenLisy(){
		 let Playing=this.Playing;
		  if(Playing=='single'){
			  this.Playing='random'
		  }
		  if(Playing=='random'){
		  	 this.Playing='Sequential'
		  }
		  if(Playing=='Sequential'){
		  	 this.Playing='single'
		  }
		  // this.Playing=name;
	  },
	  deleteFun(item,index){
		 let  myMusic=this.myMusic.filter((e,_index) => {
			  console.log(item.name!=e.name,item.name,e.name)
		  	return item.name!=e.name
		  })
		   this.$store.commit('myMusic',myMusic);
		  let myMusicList= this.myMusicList.filter((e,_index) => {
		  	return item.name!=e;
		  })
		  this.$store.commit('myMusicList',myMusicList);
		  this.songSheetList[item._index].isdownload=-1;
		  localStorage.setItem('myMusicList',myMusicList)
	  },
	  increaseFun(item,index){
		  this.myMusicList.push(item.name);
		  this.myMusic.push(item)
		  this.songSheetList[item._index].isdownload=index;
		  localStorage.setItem('myMusicList',this.myMusicList)
	  },
	  // songSheet(){
			// songSheet({}).then(({data})=>{
			// 	this.myMusic=data.filter((e,index) => {
			// 		e._index=index;
			// 		return this.myMusicList.length?this.myMusicList.indexOf(e.name)!=-1:false
			// 	})
			// 	this.songSheetList=data.map((item,index)=>{
			// 		return {
			// 			...item,
			// 			_index:index,
			// 			isdownload:this.myMusicList.length?this.myMusicList.indexOf(item.name):-1
			// 		}
			// 	});
				
			// })
	  // },
	  jindu(el){
		  _player.skipForward(el)
	  },
	  xiaAzibaofan(item,index){
		  let _index=this.CurrentPlayback.index=this.myMusic.length;
		  _player.setSong(item.src);
		   this.$store.commit('CurrentPlayback',item);
		  _player.play()
		   this.isPlay=_player._state
	  },
	  PreviousSong(){
		  let _index=this.CurrentPlayback.index-1;
		  if(_index<0){
			  _index=this.myMusic.length-1;
		  }
		  _player.setSong(this.myMusic[_index].src);
		   this.$store.commit('CurrentPlayback',this.myMusic[_index]);
		  _player.play()
		   this.isPlay=_player._state
	  },
	  NextSong(){
		 let _index=this.CurrentPlayback.index+1;
		 if(_index>this.myMusic.length-1){
		 	_index=0;
		 }
		 
		 _player.setSong(this.myMusic[_index].src);
		  this.$store.commit('CurrentPlayback',this.myMusic[_index]);
		 _player.play()
		  this.isPlay=_player._state
	  },
	  play(){
		  if(_player._state){
			   _player.pause();
		  }else{
			_player.play()
			this.PlayOften=_player._durationArr
		  }
		  this.isPlay=_player._state
	  },
	  dragStart(e) {
		  console.log(e)
		// this.dragging = true;
		// this.dragStartX = e.pageX - this.$refs.progressFill.offsetLeft;
		// document.addEventListener('mousemove', this.drag);
		// document.addEventListener('mouseup', this.dragEnd);
	  },
	  drag(e) {
		if (!this.dragging) return;
		const percent = ((e.pageX - this.$refs.progressBar.offsetLeft) / this.progressBarWidth) * 100;
		this.updateAudioTime(percent);
	  },
	  dragEnd() {
		this.dragging = false;
		document.removeEventListener('mousemove', this.drag);
		document.removeEventListener('mouseup', this.dragEnd);
	  },
  }
}
</script>
<style scoped> 
.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.progress-fill {
  height: 100%;
  background-color: #007bff;
  position: absolute;
  left: 0;
  top: 0;
}
.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.document{
    width: 6.9rem; transform-origin: 117.398px 70.1719px;
    max-height: calc(var(--vh, 1vh)* 100);
    background-color: var(--theme-bg-color);
    border-radius: .2rem;
    border: thin solid;
    border-color: var(--theme-color-line);
}
.document .ant-modal-header {
    border-bottom: none;
    border-radius: .2rem .2rem 0 0;
    padding: .2rem .3rem;
}
.document .ant-modal-title {
    color: var(--theme-text-color-darken);
    font-size: .3rem;
    font-weight: revert;
    line-height: .34rem;
    text-align: center;
}
.ant-modal-body {
    word-wrap: break-word;
    font-size: 14px;
    line-height: 1.5;
    padding: 24px;
}
.theme-box-shadow-h5{
	height: 2.2rem;
	width: 100%;
	border-radius: .1rem;
	box-sizing:  border-box;
	padding: .2rem 0;
	background-color:var(--theme-main-bg-color);
	/* var(--theme-main-bg-color); */
	/* box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06); */
}
.div-title{
	    font-size: .2rem;
	    text-align: center;
		color: var(--theme-text-color-darken);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
}
.van-progress-s{
	display: flex;
	padding: 0 .39rem;
	align-items: center;
	margin-top: .2rem;
	justify-content: space-between;
	font-size: .2rem;
	color: var(--theme-text-color);
}
.qXPKZj_vkYUaOHAZ0uBG{
	margin-top: .3rem;
	display: flex;
	color: var(--theme-text-color-darken);
	align-items: center;
	font-size: .22rem;
	justify-content: space-between;
	height: .8rem;
}
.MS7oAAtXxOHs5Zg3KrYg{
	text-align: center;
	height: .7rem;
	    width: 1.2rem;
}
.MS7oAAtXxOHs5Zg3KrYg .play{
	width: .3rem;
	color: var(--theme-primary-color);
	height: .3rem;
}
.MS7oAAtXxOHs5Zg3KrYg .YhLK63_jy5u0UKvEkMWQ{
	font-size: .24rem;
}
.play_let_right{
	color: var(--theme-primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 0.6rem;
	height: 0.6rem;
	background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
	background-position: -1.22143rem -3.36429rem;
	background-size: 7.77857rem 7.59107rem;
}
.play_let_right .play{
	width: .22rem;
	height: .22rem;
}
.play-conter{
	    position: relative;
	    width: 0.8rem;
	    height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--theme-primary-color);
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
	    background-position: 0rem -2.25455rem;
	    background-size: 8.8rem 8.58788rem;
}
.play-conter .play{
	width: .36rem;
	height: .36rem;
}
.qXY51eFlmkm2KItxoh96{
	margin-top: .2rem;
}
.xE8IyFndAfbeS8tzcBHP{
	    height: 5.24rem;
		padding: 0 .2rem !important;
		overflow: scroll;
		    background-color: var(--theme-main-bg-color);
			border-bottom-left-radius:.2rem ;
			border-bottom-right-radius:.2rem ;
}
</style>